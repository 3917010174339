<template>
  <main id="article">
    <header class="hero has-sidebar-spacing">
      <div class="container">
        <slot name="heroAfter"></slot>
        <div class="hero-content">
          <h1 class="h1">{{ title }}</h1>
          <p v-if="lead" class="lead">{{ lead }}</p>
        </div>
      </div>
    </header>
    <div class="article-layout-nav d-lg-none">
      <div v-if="$slots.toc" class="article-layout-nav-toc container">
        <button
          type="button"
          class="btn btn-white btn-sm"
          @click="tocVisible = true"
        >
          <IconListTask />
          {{ $texts('toc', 'Inhaltsverzeichnis') }}
        </button>
        <!-- TODO: Go to prev/next TOC item. -->
        <!-- Disable for now
        <button type="button" class="btn btn-white btn-sm">
          <b-icon-chevron-left />
        </button>
        <button type="button" class="btn btn-white btn-sm">
          <b-icon-chevron-right />
        </button>
        -->
      </div>
      <!--      <tabs :items="tabs" />-->
    </div>
    <Container>
      <div id="article-layout" class="article-layout">
        <slot name="before"></slot>

        <div
          v-if="slots.metadata"
          id="article-metadata"
          class="article-layout-sidebar-item"
        >
          <slot name="metadata"></slot>
        </div>

        <div
          v-if="slots.author"
          id="article-author"
          class="article-layout-sidebar-item"
        >
          <slot name="author"></slot>
        </div>

        <div
          v-if="slots.downloads"
          id="article-downloads"
          class="article-layout-sidebar-item"
        >
          <slot name="downloads"></slot>
        </div>

        <div
          v-if="slots.audios"
          id="article-audios"
          class="article-layout-sidebar-item"
        >
          <slot name="audios"></slot>
        </div>

        <div
          v-if="slots.collection"
          id="article-collection"
          class="article-layout-sidebar-item"
        >
          <slot name="collection"></slot>
        </div>

        <article
          id="article-text"
          class="article-layout-content content page-content"
        >
          <slot></slot>
        </article>

        <div
          v-if="slots.videos"
          id="article-videos"
          class="article-layout-sidebar-item"
        >
          <slot name="videos"></slot>
        </div>

        <div
          v-if="slots.toc"
          id="article-toc"
          class="article-layout-sidebar-item is-sticky is-toc"
          :class="{ 'is-visible': tocVisible }"
          @click.capture="tocVisible = false"
        >
          <slot name="toc"></slot>
        </div>

        <div
          v-if="slots.collectionSeries"
          id="collection-toc"
          class="article-layout-sidebar-item is-sticky"
        >
          <slot name="collectionSeries"></slot>
        </div>

        <slot v-if="name === 'haltefest'" name="recommendation"></slot>
      </div>
    </Container>
  </main>
</template>

<script setup lang="ts">
import IconListTask from '~icons/bi/list-task'

defineProps({
  title: {
    type: String,
    default: '',
  },
  lead: {
    type: String,
    default: '',
  },
})

const tocVisible = ref(false)
const { $texts } = useNuxtApp()
const slots = useSlots()

const { name } = useCurrentSite()

/**
 * Dynamically build the tabs.
 *
 * A tab is added if the corresponding slot has something rendered into it.
 */
// const tabs = computed(() => {
//   const tabs: TabItem[] = [
//     {
//       label: $texts('intro', 'Intro'),
//       href: '#top',
//     },
//   ]
//   if (slots.downloads) {
//     tabs.push({
//       label: $texts('downloads', 'Downloads'),
//       href: '#article-layout',
//     })
//   }
//   tabs.push({
//     label: $texts('text', 'Text'),
//     href: '#article-text',
//   })
//   if (slots.videos) {
//     tabs.push({
//       label: $texts('videos', 'Videos'),
//       href: '#article-videos',
//     })
//   }
//   if (slots.recommendation) {
//     tabs.push({
//       label: $texts('book_recommendation', 'Buchtipp'),
//       href: '#article-recommendation',
//     })
//   }
//   return tabs
// })
</script>

<style lang="scss">
@import '~/assets/scss/variables';
$article-layout-sidebar-offset-top-lg: rem(149);
$article-layout-sidebar-offset-top-xl: rem(152);

.article-layout {
  margin-bottom: $size-7;
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 1fr 20rem;
    grid-template-rows: repeat(10, auto);
    grid-gap: 0 $size-3;
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: 1fr 24rem;
    grid-gap: 0 ($size-6);
  }
}

.article-layout-content {
  @include media-breakpoint-down(sm) {
    margin-bottom: $size-4;
  }
  @include media-breakpoint-up(lg) {
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: -1;
  }
}

.article-layout-sidebar {
  @include media-breakpoint-up(xl) {
  }
}

.article-layout-sidebar-item {
  padding: $size-2 0;
  border-bottom: 1px solid $gray-400;
  &:last-child {
    border-top: 1px solid $gray-400;
  }
  @include media-breakpoint-up(lg) {
    transform: translateY(-$article-layout-sidebar-offset-top-lg);
    &.is-sticky {
      position: sticky;
      top: $article-layout-sidebar-offset-top-lg;
      z-index: 100;
    }
    padding: $size-2 $size-3;
    background: white;
    border: 1px solid $gray-400;
    margin-bottom: -1px;
    grid-column: 2;
  }
  @include media-breakpoint-up(xl) {
    transform: translateY(-$article-layout-sidebar-offset-top-xl);
    &.is-sticky {
      top: $article-layout-sidebar-offset-top-xl;
    }
  }
}
.sidebar-item {
  &:not(:last-child) {
    border-bottom: 1px solid $gray-300;
  }
}

.sidebar-item-content {
  &.is-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.sidebar-label {
  font-size: $font-size-sm;
  font-weight: $font-weight-semibold;
  letter-spacing: 0.025em;
  max-width: 75%;
  &:not(a) {
    text-transform: uppercase;
  }
  &.normal {
    text-transform: none !important;
  }
}
a.sidebar-label:hover {
  text-decoration: underline;
}

.article-meta-list {
  display: flex;
  justify-content: space-between;
  li {
    display: flex;
    flex-direction: column;
    span:last-child {
      font-size: $h4-font-size;
      font-weight: $font-weight-light;
      line-height: 1.1;
    }
  }
}

.article-layout-sidebar-item.is-toc {
  @include media-breakpoint-down(lg) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5000;
    padding: 1rem;
    background: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
    align-items: center;

    .sidebar-item-content {
      background: white;
      padding: 1.5rem;
      overflow: auto;
      max-height: 100%;
      width: 100%;
    }

    &.is-visible {
      display: flex;
    }
  }

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.article-layout-sidebar-item.is-book-cover {
  padding: 0;
  border: 0;
  width: 4.5rem;
  float: left;
  margin-right: 1rem;
  margin-top: 0.5rem;
  @include media-breakpoint-up(lg) {
    width: 100%;
    margin-bottom: 2rem;
  }
  @include media-breakpoint-up(xl) {
    margin-top: -4rem;
  }
  img {
    width: 100%;
  }
}

.article-layout-nav {
  z-index: 100;
  position: sticky;
  top: 0;
  background: $gray-200;
  border-bottom: 1px solid $border-color;
}

.article-layout-nav-toc {
  display: flex;
  padding-top: calc($grid-gutter-width / 2);
  margin-top: -(calc($grid-gutter-width / 2));
  margin-bottom: 1rem;
  .btn:first-child {
    flex: 1;
    text-align: left;
    margin-right: 0.25rem;
    font-weight: $font-weight-semibold;
  }
}
</style>
